/* Importing Google Fonts for aesthetic typography */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* Base styles for the Chill container */
.chill-container {
    font-family: 'Playfair Display', serif;
    max-width: 600px;
    margin: 30px auto 20px; /* Centered in the viewport with top margin */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    background-color: #ffffff;
}

/* Flexbox setup for navigation tabs */
.chill-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.chill-tab {
    flex-grow: 1;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    transition: border-color 0.3s;
}

.chill-tab:hover, .chill-tab.active {
    border-bottom-color: rgb(126, 217, 87);
}

.tab-content {
    padding: 20px;
    text-align: left;
}



.error-message {
    color: #ff4d4f;
    font-weight: bold;
}






