/* Quiz Container - Centered, Responsive, and with Playfair Display font */
.quiz-container {
  font-family: 'Playfair Display', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  width: 90%; /* Responsive width */
  max-width: 600px; /* Maximum width */
  margin: 20px auto;
  text-align: center;
  box-sizing: border-box; /* Includes padding and border in width */
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .quiz-container {
    width: 95%; /* Takes more screen width on smaller devices */
    padding: 15px; /* Slightly reduced padding */
  }

  .question-text, .question-title, .quiz-title {
    font-size: 16px; /* Adjusting text size for smaller screens */
  }

  .answer-option {
    padding: 8px; /* Smaller padding for answer options */
  }
}

/* Title and Text Elements Styling */
.quiz-title, .question-title {
  color: #333;
  margin-bottom: 15px;
}

.question-section, .review-section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.question-title {
  font-size: 20px;
  font-weight: bold;
}

.question-text {
  font-size: 18px;
}

.answer-option {
  display: block;
  width: 100%; /* Full width for touch accessibility */
  padding: 10px;
  margin: 5px auto;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.answer-option:hover, .answer-option.selected {
  background-color: #d3d3d3; /* Darker on hover and when selected */
}

.score-section {
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #4CAF50; /* Green for scores */
}

.replay-button, .review-button {
  padding: 8px 16px;
  background-color: #4CAF50; /* Green buttons */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.replay-button:hover, .review-button:hover {
  background-color: #367c39; /* Darker green on hover */
}