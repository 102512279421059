@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* Main container styling */
.writing-practice-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    font-family: 'Playfair Display', serif;
}

/* Heading styling */
.writing-practice-heading {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
}

/* Container for displaying the API response */
.response-container {
    padding: 15px;
    margin-top: 20px;
    background-color: #ffffef;
    font-size: 16px;
    color: #333;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-radius: 4px;
}

/* Styling for the textarea used for input */
.textarea-input {
    width: calc(100% - 40px); /* Adjust width to account for padding */
    height: 150px;
    padding: 10px;
    margin: 0 20px 10px 20px; /* Apply margin symmetrically */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.05);
    resize: vertical;
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    box-sizing: border-box;
}

/* Grouping buttons and aligning them to the left */
.button-group {
    display: flex;
    justify-content: flex-start;
    margin: 0 20px; /* Ensure button group aligns with textarea margin */
}

/* Styling for both the submit and reset buttons */
.submit-button, .resetWritingPractice {
    padding: 10px 15px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px; /* Space between buttons */
}

.submit-button {
    background-color: #4CAF50;
}

.submit-button:hover {
    background-color: #45a049;
}

.resetWritingPractice {
    background-color: #f44336;
}

.resetWritingPractice:hover {
    background-color: #d32f2f;
}

/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
    .writing-practice-container {
        padding: 10px;
    }

    .textarea-input, .button-group {
        width: calc(100% - 20px); /* Adjust width for mobile view */
        margin: 0 10px; /* Adjust margins for smaller screens */
    }

    .button-group {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .submit-button, .resetWritingPractice {
        width: 100%; /* Full width for easier access on touch devices */
        margin: 5px 0; /* Add space between buttons */
        padding: 12px 0; /* Larger padding for easier interaction */
    }
}
