@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.joke-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: 'Playfair Display', serif;
}

.form-group {
    margin-bottom: 15px;  /* Consistent bottom margin for spacing */
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    appearance: none; /* Removes default styling and allows for custom styling */
    background-color: white;
    cursor: pointer;
}

.generate-button,
.reset-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: block; /* Make buttons block level for full width */
    width: 100%;
    margin-top: 10px;
}

.generate-button {
    background-color: #4CAF50;
}

.generate-button:hover {
    background-color: #45a049;
}

.reset-button {
    background-color: #ff4d4f;
    margin-top: 5px;
}

.reset-button:hover {
    background-color: #e64545;
}
