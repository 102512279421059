@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.motivation-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: 'Playfair Display', serif;
}

.motivation-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.motivation-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #4CAF50;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: block;
}

.motivation-button:hover {
    background-color: #45a049;
}

.motivation-quote {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    text-align: center;
}
