@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Playfair Display', serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}

.description {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.input-area {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.input-field {
  font-size: 16px;
  padding: 10px;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px; /* Space between input and button */
}

.button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.error {
  color: #ff0000;
  margin-top: 10px;
}

.details {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  width: 100%;
  overflow-wrap: break-word;
  text-align: left;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .input-area {
      flex-direction: column;
      align-items: center; /* Center align the flex items */
      width: 100%; /* Full width to use all available space */
    }
  
    .input-field,
    .button {
      width: 100%; /* Both input field and button take full width */
      margin-right: 0; /* Remove any right margin */
      margin-bottom: 10px; /* Add some bottom margin for spacing */
      height: 40px; /* Consistent height for both */
      padding: 10px 15px; /* Adjust padding to ensure text is vertically centered */
      box-sizing: border-box; /* Include padding in the height calculation */
    }
  
    .button {
      margin-bottom: 0; /* Last item, no need for bottom margin */
      padding: 10px 15px; /* Match input field padding for consistent look */
    }
  }
  