@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Playfair Display', serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 2rem; /* Maintain text size */
  padding-right: 10px; /* Ensure there's enough space for the button */
}



.heading .text-center {
  flex-grow: 1; /* Allows the text to take up any extra space, ensuring centering */
  position: relative;
  z-index: 1; /* Keeps text above the pseudo-element */
}

.speaker-button {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: 18px;
}



.date-display {
  cursor: pointer;
  margin-bottom: 10px; /* Provides space for calendar overlay */
  position: relative; /* Context for positioning calendar overlay */
}

.dropdown {
  background-color: transparent;
  color: #000000; /* Link color */
  border: none;
  cursor: pointer;
  font-family: 'Playfair Display', serif;
  font-size: 12px;
  appearance: none; /* Removes default styles from select elements */
  position: relative;
  outline: none;
  display: inline-block; /* Ensures the dropdown wraps tightly around the text */
  border-bottom: 2px solid #00aaff; /* Adds a blue bottom border */
  padding-bottom: 2px; /* Slight padding below the text for spacing */
  text-align: center;
  bottom: 25px

}


.controls {
  position: absolute;
  right: 10px; /* Adjust for top right positioning */
  top: 20px; /* Adjust for alignment with the top */
  display: inline-flex;
  align-items: center;
  white-space: nowrap; /* Prevents wrapping to ensure accurate underline */
}

.word {
  font-size: 40px; /* Adjust font size dynamically */
  font-style: bold;
  max-width: 100%;
  overflow-wrap: break-word;
  color: #4CAF50;
}

.definition {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
}

.definition p {
  font-weight: normal;
  margin: 10px 0;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .controls {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    text-align: center;
  }

  .dropdown {
    padding: 8px 10px;
    padding-bottom: 1px;
    top: 5px
    
  }

  .date-display {
    margin-top: 40px;
  }
}

.date-nav {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: 20px; /* Adjust size as needed */
  padding: 0 10px;
  color: #00aaff;
}
