/* Import the Fairplay Display font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Fairplay+Display:wght@400;700&display=swap');

/* Game Container - Centered, Responsive, and with Fairplay Display font */
.game-container {
  font-family: 'Fairplay Display', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* White background for outer container */
  padding: 20px;
  border-radius: 8px;
  width: 100%; /* Use 100% for better responsiveness */
  max-width: 600px; /* Maximum width */
  margin: 20px auto;
  text-align: center;
  box-sizing: border-box; /* Includes padding and border in width */
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .game-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
    max-width: 95%; /* Adjust maximum width for small devices */
  }
}

/* Dropdown menu styling */
select {
  width: 100%; /* Full width of the container */
  padding: 8px;
  margin-bottom: 20px; /* Spacing below the dropdown */
  font-size: 16px; /* Larger font for readability */
  text-align-last: center; /* Ensure the selected option is centered */
  
}

/* Quotes container within the game */
.quotes-container {
  width: 100%; /* Full width */
  margin: 20px 0; /* Vertical spacing */
}

/* Individual quote items */
.quote-item {
  background-color: rgb(255, 255, 255); /* Transparent background for individual quotes */
  border: 2px solid #ccc; /* Solid border for each quote */
  padding: 10px 15px; /* Padding inside each quote box */
  margin: 8px 0;
  font-size: 16px; /* Increase font size for readability */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text to the left within the quote box */
  border-radius: 10px; /* Rounded corners for quote items */
  cursor: pointer;
  width: 100%; /* Ensure full-width coverage */
  box-sizing: border-box; /* Border and padding included in width */
  text-align: left; /* Text aligned to the left */
}

.quote-item.dragging {
  border-color: #aaa; /* Darker border when dragging */
  background-color: #f0f0f0; /* Slightly darker background when dragging */
}

.check-order-button {
  background-color: #4CAF50; /* Green button */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

.check-order-button:hover {
  background-color: #367c39; /* Darker green on hover */
}
