/* General styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;
  color: #000000;
  padding-top: 30px;
}

.heading-cursive {
  font-family: "Playfair Display", "Cursive";
  font-size: 42px; /* This is just an example; choose your preferred cursive font */
  margin-top: 0;
  margin-bottom: 0; 
 
}

.app-container {
  max-width: 100%;
  /* Rest of your .app-container styles */
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background: #fffffffa;
  padding: 10px 20px 10px 0px; /* Adjusted padding for right side */
  box-shadow: none;
  z-index: 9;
}

.logo {
  height: 40px;  /* Adjust logo size as needed */
  width: auto;   /* Maintain aspect ratio */
  margin-left: 20px; /* Adds space to the left, moving the logo to the right */
}




/* Responsive adjustments */
@media (max-width: 768px) {
  body, html {
    padding-top: 10;
  }

  .app-header {
    position: flex;
  }

 



  
}



/* WritingPractice.css */
.textarea-input {
  width: 80%; /* Adjust width to fit your layout */
  height: 200px; /* Sufficient height to accommodate multiple lines of text */
  margin: 10px 0;
  padding: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px; /* Larger font size for better readability */
  line-height: 1.5; /* Enhanced line spacing */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
  resize: vertical; /* Allow vertical resizing only */
}

.game-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #fff;
}

.game-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.game-tabs button {
  flex-grow: 1;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.game-tabs button.active {
  color: #0056b3;
  border-bottom: 3px solid #0056b3;
}

.game-tabs button:hover {
  background-color: #f1f1f1;
}

.quote-item {
  padding: 8px;
  margin: 4px 0;
  background-color: #f0f0f0;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.quote-item:hover {
  background-color: #e9ecef;
}

.quote-item.dragging {
  background-color: #e1e1e1;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  transform: scale(1.05);
}

/* Additional styles for form elements */
select {
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Additional styles for dropdown menu */
.literary-jigsaw select {
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none; /* Removes any box-shadow if not required */
  appearance: none; /* For custom dropdown styling */
  background-color: white;
  margin-bottom: 20px;
  width: auto; /* or 100% for full width */
}

/* Responsive adjustments for dropdown menu */
@media (max-width: 768px) {
  .literary-jigsaw select {
    width: 100%; /* Ensure dropdown is full width on mobile */
    padding: 12px; /* Bigger touch target */
  }
}


/*GAME TAB DESIGN*/

.tabs {
  display: flex;
  justify-content: center; /* Aligns tabs to the center horizontally */
  background-color: #fff; /* White background color for the tabs */
  padding-bottom: 1px; /* Slight padding to offset the border of the active tab */
}

.tab {
  flex-grow: 1;
  text-align: center;
  padding: 10px 20px; /* Vertical and horizontal padding */
  cursor: pointer;
  background-color: transparent; /* Transparent background for a clean look */
  border: none; /* No border for a flat design */
  border-bottom: 2px solid transparent; /* Invisible border to keep text alignment consistent */
  color: #333; /* Grey color for the tab text */
  transition: color 0.3s, border-color 0.3s; /* Smooth transition for color and border */
  position: relative; /* Needed for the positioning of the pseudo-elements */
  margin-bottom: 20px; /* Additional space below the tab */
}

.tab:hover,
.tab:focus {
  border-bottom-color: rgb(126, 217, 87); /* Green bottom border on hover/focus */
  border-bottom: 3px solid rgb(126, 217, 87);
}

.tab.active-tab {
  color: #333; /* Grey text color for the active tab */
  border-bottom-color: rgb(126, 217, 87); /* Green bottom border for the active tab */
  border-bottom: 3px solid rgb(126, 217, 87);
}

/* Tab content */
.tab-content {
  border-top: 1px solid #ccc; /* Top border for the content area, directly below tabs */
  padding: 40px; /* Increased top padding inside the content area */
}

@media (max-width: 768px) {
  .tab {
    padding: 10px 15px; /* Smaller padding for smaller screens */
  }

  

}








/* Correct the .nav-menu display property */
.nav-menu {
  display: flex; /* Use flexbox to layout the links */
  justify-content: center; /* Center the links */
  align-items: center; /* Align the items vertically */
  padding: 0;
  margin-top: 10px;
  list-style-type: none; /* Remove bullet points from list */
  background-color: #fffffffa;

}

/* Style for the .nav-link */
.nav-link {
  padding: 10px 15px;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #333; /* Grey color for the tab text */
  display: inline-block; /* Tabs will be inline */
  margin-right: 10px; /* Space between tabs */
  transition: color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
  border-bottom: 3px solid transparent; /* Invisible border to maintain layout consistency */
}


/* Style for the active .nav-link */
.nav-link.active {
  color: #000; /* Text color for active link */
  border-bottom: 3px solid rgb(126, 217, 87); /* Green bottom border for active link */
}

.nav-link:hover {
  border-bottom: 3px solid rgb(126, 217, 87); /* Green border on hover */
}

.hamburger {
  background: none; /* No background */
  border: 0; /* No border */
  outline: none; /* Removes the outline */
  box-shadow: none; /* No box shadow */
  cursor: pointer;
  font-size: 25px; /* Adjust size as necessary */
  color: #333; /* Minimalistic color, can change based on your design */
  padding: 0.5em; /* Provides space around the icon for easier clicking */
  z-index: 11; /* Above other content */
  display: none; /* Hidden by default, shown in mobile view */
}

.hamburger:hover {
  color: rgb(96, 96, 96); /* Change font color to grey on hover */
}

/* Mobile styles */
@media (max-width: 768px) {
  .overlay {
    display: none; /* Hidden by default */
    position: fixed; /* Positioned relative to the viewport */
    top: 325px; /* Starts below the navigation bar; adjust as per your navigation bar's actual height */
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.353); /* Semi-transparent black */
    z-index: 10; /* Below the nav menu, but above other content */
  }
  
  /* Show the overlay when the 'is-active' class is added */
  .overlay.is-active {
    display: block;
  }



/* Content area styling */
.tab-content {
  padding: 2rem;
}


.nav-menu {
  display: none; /* Hidden by default */
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  background: rgb(255, 255, 255);
  padding-bottom: 10px;
  
}

.nav-menu.is-open {
  display: flex; /* Show when menu is toggled */
}

.close-menu {
  display: none; /* Hidden by default */
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

}







}